.main {
  background-image: url("../../assets/Home/bg.png");
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ======= Wishlist Icons ======= */
.heart-icon {
  font-size: 21px;
}
